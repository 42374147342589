import React from 'react'
import { navigate } from 'gatsby'

export default function Init() {
  setTimeout(() => {
    navigate('/')
  }, 500)

  return <div />
}
